import { set, lensPath } from "ramda";

import { UNCERTAINTY, IMPORTANCE } from "./config";

const TaskInfoPanel = ({
  tasks,
  setTasks,
  selectedTaskId,
  setSelectedTaskId,
  config
}) => {
  const selectedTask = tasks.find((task) => task.id === selectedTaskId);
  return (
    <>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h2>Task Info</h2>
        <button
          onClick={() => {
            setSelectedTaskId(undefined);
          }}
        >
          close
        </button>
      </div>
      <div style={{ display: "grid", gap: 20 }}>
        <div style={{ display: "grid", gap: 10 }}>
          <strong style={{ display: "block" }}>Name</strong>
          <input
            type="text"
            value={selectedTask.name}
            onChange={({ target: { value } }) => {
              setTasks((current) => {
                const path = lensPath([
                  current.findIndex(({ id }) => id === selectedTask.id),
                  "name"
                ]);
                return set(path, value, current);
              });
            }}
          />
        </div>
        {selectedTask.timeRemaining === 0 ? null : (
          <>
            <div style={{ display: "grid", gap: 10 }}>
              <strong style={{ display: "block" }}>Uncertainty</strong>
              <small style={{ display: "block" }}>
                How well is the task understood? Are there things that might go
                wrong? Is there scope enought that there could be unknown
                unknowns? This is used for pessimistic and optimistic estimates
                only.
              </small>
              <select
                value={selectedTask.uncertainty}
                onChange={({ target: { value } }) => {
                  setTasks((current) => {
                    const path = lensPath([
                      current.findIndex(({ id }) => id === selectedTask.id),
                      "uncertainty"
                    ]);
                    return set(path, value, current);
                  });
                }}
              >
                {Object.entries(UNCERTAINTY).map(([key, { label }]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: "grid", gap: 10 }}>
              <strong style={{ display: "block" }}>Importance</strong>
              <small style={{ display: "block" }}>
                How important is it to get this task done? If the task is a
                dependencies of another task with a higer importance, that will
                take precedence.
              </small>
              <select
                value={selectedTask.importance}
                onChange={({ target: { value } }) => {
                  setTasks((current) => {
                    const path = lensPath([
                      current.findIndex(({ id }) => id === selectedTask.id),
                      "importance"
                    ]);
                    return set(path, value, current);
                  });
                }}
              >
                {Object.entries(IMPORTANCE).map(([key, { label }]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TaskInfoPanel;
