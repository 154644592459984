const RadioButton = ({ children, value, checked, onChange }) => (
  <label
    style={{
      display: "block",
      textAlign: "center",
      background: checked ? "#def" : null,
      border: "1px solid #ccc",
      padding: 5
    }}
  >
    <div style={{ display: "block" }}>
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={(event) => {
          onChange(event);
        }}
      />
    </div>
    <div style={{ display: "block" }}>{children}</div>
  </label>
);

const TimeEstimateInput = ({ hoursPerDay, value, onChange }) => (
  <>
    <div
      style={{
        display: "grid",
        gap: 5,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
        alignItems: "stretch"
      }}
    >
      {[
        { value: 0, label: "Ephemeral" },
        { value: 0.25, label: "¼ hour" },
        { value: 0.5, label: "½ hour" },
        { value: 1, label: "1 hour" },
        { value: 2, label: "2 hours" },
        { value: 3, label: "3 hours" },
        { value: hoursPerDay * 0.5, label: "½ day" },
        { value: hoursPerDay * 1, label: "1 day" },
        { value: hoursPerDay * 2, label: "2 days" },
        { value: hoursPerDay * 3, label: "3 days" },
        { value: hoursPerDay * 4, label: "4 days" }
      ].map((combo, column) => (
        <div style={{ gridColumn: column + 1 }}>
          <RadioButton
            key={combo.value}
            value={combo.value}
            checked={combo.value === value}
            onChange={(event) => {
              event.persist();
              onChange(combo.value, event);
            }}
          >
            {combo.label}
          </RadioButton>
        </div>
      ))}
    </div>
    <div
      style={{
        display: "grid",
        gap: 5,
        gridAutoFlow: "column",
        alignItems: "center",
        justifyContent: "start"
      }}
    >
      <span>Hours:</span>
      <div>
        <input
          type="number"
          value={value}
          onChange={(event) => {
            event.persist();
            onChange(parseFloat(event.target.value, 10), event);
          }}
        />
        {/* <select
        value={value.units}
        onChange={(event) => {
          event.persist();
          onChange({ value: value.value, units: event.target.value }, event);
        }}
      >
        {["minutes", "hours", "days", "weeks", "months"].map((unit) => (
          <option key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select> */}
      </div>
    </div>
  </>
);

export default TimeEstimateInput;
