const TaskDependenciesPanel = ({
  tasks,
  setTasks,
  selectedTaskId,
  setSelectedTaskId,
  config
}) => {
  const selectedTask = tasks.find((task) => task.id === selectedTaskId);
  return (
    <>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h2>Task Dependencies</h2>
        <button
          onClick={() => {
            setSelectedTaskId(undefined);
          }}
        >
          close
        </button>
      </div>
      <div style={{ display: "grid", gap: 20 }}>
        <div style={{ display: "grid", gap: 10 }}>
          <strong style={{ display: "block" }}>Dependencies</strong>
          <small style={{ display: "block" }}>
            What tasks must be completed before this task can be considered
            complete.
          </small>
          TBA
        </div>
      </div>
    </>
  );
};

export default TaskDependenciesPanel;
