import { v4 as uuid } from "uuid";
import { set, view, lensPath } from "ramda";

import range from "./range";
import coalease from "./coalease";

const SprintCapacityPanel = ({ config, setConfig }) => {
  return (
    <>
      <h2>Capacity</h2>
      <label style={{ display: "block" }}>
        Hours per day:{" "}
        <input
          type="number"
          value={config.hoursPerDay}
          onChange={({ target: { value } }) => {
            setConfig((current) => ({
              ...current,
              hoursPerDay: value
            }));
          }}
        />
      </label>
      <div>
        <div style={{ display: "grid", gridAutoFlow: "column" }}>
          {config.people.map((person, index) => (
            <div
              key={person.id}
              style={{
                gridRow: 1,
                gridColumn: index + 2
              }}
            >
              <input
                value={person.name}
                onChange={({ target: { value } }) => {
                  setConfig((current) => ({
                    ...current,
                    people: current.people.map((p) =>
                      p.id === person.id ? { ...p, name: value } : p
                    )
                  }));
                }}
              />
              <button
                style={{
                  gridRow: 1,
                  gridColumn: config.people.length + 2
                }}
                onClick={() => {
                  setConfig((current) => ({
                    ...current,
                    people: current.people.filter(({ id }) => id !== person.id)
                  }));
                }}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            style={{
              gridRow: 1,
              gridColumn: config.people.length + 2
            }}
            onClick={() => {
              setConfig((current) => ({
                ...current,
                people: [
                  ...current.people,
                  { id: uuid(), name: "Person", capacity: [] }
                ]
              }));
            }}
          >
            Add
          </button>
          {range(0, config.totalDays).map((day) => (
            <>
              <div
                style={{
                  gridRow: day + 2,
                  gridColumn: 1
                }}
              >
                Day {day + 1}
              </div>
              {config.people.map((person, index) => (
                <div
                  key={`${person.id}-${day}`}
                  style={{
                    cursor: "default",
                    gridRow: day + 2,
                    gridColumn: index + 2
                  }}
                  onClick={() => {
                    setConfig((current) => {
                      const path = lensPath([
                        "people",
                        current.people.findIndex(({ id }) => id === person.id),
                        "capacity",
                        day
                      ]);
                      const capacity = coalease(view(path, current), 1);
                      return set(
                        path,
                        capacity === 1 ? 0.5 : capacity === 0.5 ? 0 : 1,
                        current
                      );
                    });
                  }}
                >
                  {coalease(person.capacity[day], 1) === 1
                    ? "✅"
                    : coalease(person.capacity[day], 1) === 0
                    ? "❌"
                    : "½"}
                </div>
              ))}
            </>
          ))}
          <div
            style={{
              gridRow: config.totalDays + 2,
              gridColumn: 1
            }}
          >
            <button
              onClick={() => {
                setConfig((current) => ({
                  ...current,
                  totalDays: current.totalDays + 1
                }));
              }}
            >
              Add
            </button>
            <button
              onClick={() => {
                setConfig((current) => ({
                  ...current,
                  totalDays: current.totalDays - 1
                }));
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SprintCapacityPanel;
