import { useState } from "react";
import { v4 as uuid } from "uuid";

import TaskInfoPanel from "./TaskInfoPanel";
import TaskEstimatePanel from "./TaskEstimatePanel";
import TaskDependenciesPanel from "./TaskDependenciesPanel";
import SprintCapacityPanel from "./SprintCapacityPanel";
import getEstimates from "./getEstimates";
import range from "./range";
import coalease from "./coalease";

import "./styles.css";

const App = () => {
  const [config, setConfig] = useState({
    hoursPerDay: 8,
    totalDays: 10,
    people: [
      {
        id: uuid(),
        name: "Person A",
        capacity: [1, 0.5, 0, 1],
      },
    ],
  });

  const [tasks, setTasks] = useState([
    {
      id: uuid(),
      name: "Example",
      complete: false,
      timeRemaining: 0.25,
      people: [
        {
          id: uuid(),
          commitment: 0.8,
        },
        {
          id: uuid(),
          commitment: 0.3,
        },
      ],
      uncertainty: "extreme",
      importance: "high",
    },
  ]);

  const [selectedTaskId, setSelectedTaskId] = useState(undefined);

  const selectedTask = tasks.find((task) => task.id === selectedTaskId);

  const totals = {
    available: config.people.reduce(
      (total, person) =>
        total +
        range(0, config.totalDays).reduce(
          (x, day) =>
            x + coalease(person.capacity[day], 1) * config.hoursPerDay,
          0
        ),
      0
    ),
    ...tasks
      .filter((task) => !task.complete)
      .reduce(
        (result, task) => {
          const estimates = getEstimates(task, config);
          return {
            optimistic: result.optimistic + estimates.optimistic,
            likely: result.likely + estimates.likely,
            pessimistic: result.pessimistic + estimates.pessimistic,
          };
        },
        { optimistic: 0, likely: 0, pessimistic: 0 }
      ),
  };

  return (
    <div
      style={{
        display: "grid",
        gap: 20,
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
      }}
    >
      <div>
        <h2>Tasks</h2>
        <ol>
          {tasks.map((task) => (
            <li
              key={task.id}
              style={{ background: selectedTaskId === task.id ? "#ff0" : null }}
              onClick={() => {
                setSelectedTaskId(task.id);
              }}
            >
              {task.name}
            </li>
          ))}
        </ol>
        <button
          onClick={() => {
            setTasks((current) => [
              ...current,
              {
                id: uuid(),
                name: "Untitled task",
                timeRemaining: 1,
                people: [],
                uncertainty: "normal",
                importance: "normal",
              },
            ]);
          }}
        >
          Add
        </button>
      </div>
      <div>
        {selectedTask ? (
          <>
            <TaskInfoPanel
              tasks={tasks}
              setTasks={setTasks}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              config={config}
            />
            <TaskEstimatePanel
              tasks={tasks}
              setTasks={setTasks}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              config={config}
            />
            <TaskDependenciesPanel
              tasks={tasks}
              setTasks={setTasks}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              config={config}
            />
          </>
        ) : (
          <>
            <SprintCapacityPanel config={config} setConfig={setConfig} />
            <h2>Total Hours</h2>
            <div>Available: {totals.available.toFixed(2)}</div>
            <div>Optimistic: {totals.optimistic.toFixed(2)}</div>
            <div>Likely: {totals.likely.toFixed(2)}</div>
            <div>Pessimistic: {totals.pessimistic.toFixed(2)}</div>
            <h2>Sequencing</h2>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
