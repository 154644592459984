import { UNCERTAINTY } from "./config";

const getEstimates = (task) => {
  const likely =
    task.timeRemaining *
    task.people.reduce((sum, person) => sum + person.commitment, 0);
  const variation = likely * UNCERTAINTY[task.uncertainty].multiplier;
  const optimistic = likely - variation;
  const pessimistic = likely + variation;

  return {
    optimistic,
    likely,
    pessimistic
  };
};

export default getEstimates;
