import { set, lensPath } from "ramda";

import TimeEstimateInput from "./TimeEstimateInput";

const TaskEstimatePanel = ({
  tasks,
  setTasks,
  selectedTaskId,
  setSelectedTaskId,
  config
}) => {
  const selectedTask = tasks.find((task) => task.id === selectedTaskId);
  return (
    <>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h2>Task Estimate</h2>
        <button
          onClick={() => {
            setSelectedTaskId(undefined);
          }}
        >
          close
        </button>
      </div>
      <div style={{ display: "grid", gap: 20 }}>
        <div style={{ display: "grid", gap: 10 }}>
          <strong style={{ display: "block" }}>Time Remaining</strong>
          <small style={{ display: "block" }}>
            How long until this task will be finished? Do not inflate this time
            to account for multiple people working on the task or exclude from
            it any "pauses" in the work that may arise from waiting for
            dependencies or because of interruptions. Instead, imagine a
            stopwatch starting when the first bit of work begins and stopping
            when the task is considered complete. If you're unsure, err on the
            side of caution and round it up.
          </small>
          <TimeEstimateInput
            hoursPerDay={config.hoursPerDay}
            value={selectedTask.timeRemaining}
            onChange={(value) => {
              setTasks((current) => {
                const path = lensPath([
                  current.findIndex(({ id }) => id === selectedTask.id),
                  "timeRemaining"
                ]);
                return set(path, value, current);
              });
            }}
          />
        </div>
        {selectedTask.timeRemaining === 0 ? null : (
          <>
            <div style={{ display: "grid", gap: 10 }}>
              <strong style={{ display: "block" }}>People</strong>
              <small style={{ display: "block" }}>
                How many people are going to work on this? If it's a single
                person non-stop for the full duration, that'd be 1. If it's 1
                person for 80% of it, and another for 20% of it, that'd also be
                1. If it's two people working in parallel for the whole piece of
                work, that's 2. If it's 1 person, but they're going to be really
                stop-start on it because they're waiting on emails etc, that'll
                be less than 1.
              </small>
              {selectedTask.people.map((person) => (
                <>
                  <input
                    key={person.id}
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={person.commitment}
                    onChange={({ target: { value } }) => {
                      setTasks((current) => {
                        const path = lensPath([
                          current.findIndex(({ id }) => id === selectedTask.id),
                          "people",
                          selectedTask.people.findIndex(
                            ({ id }) => id === person.id
                          ),
                          "commitment"
                        ]);
                        return set(path, parseFloat(value, 10), current);
                      });
                    }}
                  />
                  {person.commitment}
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TaskEstimatePanel;
