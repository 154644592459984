const UNCERTAINTY = {
  normal: { priority: 0, multiplier: 0.34, label: "Normal" }, // 1 standard deviation
  high: { priority: 1, multiplier: 0.48, label: "High" }, // 2 standard deviations
  extreme: { priority: 2, multiplier: 0.5, label: "Extreme" } // 3 standard deviations
};

const IMPORTANCE = {
  high: { priority: 2, label: "High" },
  normal: { priority: 1, label: "Normal" },
  low: { priority: 0, label: "Low" }
};

export { UNCERTAINTY, IMPORTANCE };
